import ReactGA from 'react-ga4';

export const logPageView = () => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
};

export const trackEvent = (category, action, label) => {
    ReactGA.event({
      category,
      action,
      label,
    });
  };