import React from "react";
import Card from "react-bootstrap/Card";
import { SlArrowRight } from "react-icons/sl";


function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Niraj Sharma </span>
            from <span className="purple"> Mumbai, India.</span>
            <br />
            I currently have 7+ years (working) experience, with various technologies.
            <br />
            I have completed Bachelor of Engineering (BE) from Mumbai University
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <SlArrowRight /> Travelling
            </li>
            <li className="about-activity">
              <SlArrowRight /> Listening Music
            </li>
            <li className="about-activity">
              <SlArrowRight /> Driving Bikes/ Cars
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Embrace the change, for it's the only constant that leads to growth!"{" "}
          </p>
          <footer className="blockquote-footer">Niraj S.</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
