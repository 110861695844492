import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { logPageView } from "../../utils/g-analytics";

import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import {
  EWALLET,
  ANALYSIS,
  COVID19,
  CRYPTO,
  RESORT,
  AISEARCH,
  INGREDIENTS_1,
  INGREDIENTS_2,
  SHOPIFY_DASHBOARD_1,
  ADS_ANALYTICS_1,
  EDUCATION_AI,
} from "../../assetsData/index";

function Projects() {
  useEffect(() => {
    logPageView();
  }, []);

  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={INGREDIENTS_1}
              isBlog={false}
              title="Kioni Platform"
              description=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={INGREDIENTS_2}
              isBlog={false}
              title="Kioni Snippet"
              description="Built different solutions for [ Shopify / Non-Shopify / Product Finder ] "
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={SHOPIFY_DASHBOARD_1}
              isBlog={false}
              title="Shopify Admin App"
              description=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ADS_ANALYTICS_1}
              isBlog={false}
              title="Observe Pro"
              description=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={EDUCATION_AI}
              isBlog={false}
              title="Janison AI"
              description=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={AISEARCH}
              isBlog={false}
              title="Custom Search Tool - AI"
              description="Tailored AI solution designed to streamline data search and retrieval with (Private GPT) within a comprehensive handbook. Users can easily query information, receive context-aware responses, and navigate complex datasets, enhancing their ability to find relevant information quickly and efficiently."
            />
          </Col>


          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ANALYSIS}
              isBlog={false}
              title="CBM Dashboard"
              description="Live Tableau via Socket enables real-time analysis of big data across various metrics, including RSA, sales, SLA, location, and current queries. By leveraging socket connections, it provides dynamic charting and visualization, allowing users to monitor and interpret data instantly for informed decision-making."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={EWALLET}
              isBlog={false}
              title="SSO"
              description=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={EWALLET}
              isBlog={false}
              title="EPortal"
              description=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={EWALLET}
              isBlog={false}
              title="Credit Solutions"
              description=""
            />
          </Col>
          
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={EWALLET}
              isBlog={false}
              title="Any Information"
              description=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={EWALLET}
              isBlog={false}
              title="Email Application"
              description=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={EWALLET}
              isBlog={false}
              title="Pickup & Dropoff Vehicle Microservice"
              description=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={EWALLET}
              isBlog={false}
              title="Artemis Warehouse"
              description=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={EWALLET}
              isBlog={false}
              title="MS-EWallet"
              description="Microservice-based double-entry e-wallet solution that facilitates secure transactions and fund management. Users can easily make payments, track financial activities, and manage their funds. With a scalable architecture, it ensures reliability and efficiency for both individuals and businesses."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={COVID19}
              isBlog={false}
              title="Covid 19 analyzer"
              description="Location-based tool that tracks the origins and spread of the virus with a month-over-month (MoM) and year-over-year (YoY) analysis by country. It provides insights into infection rates, recovery rates, and trends at city, region, and country levels, helping users understand the pandemic's impact and response over time."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={CRYPTO}
              isBlog={false}
              title="Flow Web Crypto"
              description="Platform that provides insights into cryptocurrency transactions and conversions based on user location. It allows users to monitor their crypto wallets, track transaction history, and analyze regional trends, empowering them to make informed decisions about their digital assets."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={RESORT}
              isBlog={false}
              title="Joggies Raj Resort"
              description="Comprehensive booking platform for the resort, offering easy reservations and real-time availability. Guests can explore various accommodations, check out upcoming events, and enjoy tailored experiences, making their stay memorable and hassle-free."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={EWALLET}
              isBlog={false}
              title="Resident Management System"
              description=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={EWALLET}
              isBlog={false}
              title="Visitor Verification"
              description=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={EWALLET}
              isBlog={false}
              title="Krazy Prints"
              description=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={EWALLET}
              isBlog={false}
              title="Good Tenders"
              description=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={EWALLET}
              isBlog={false}
              title="VIP Numero"
              description=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={EWALLET}
              isBlog={false}
              title="Authors / Books Management Microservice"
              description=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={EWALLET}
              isBlog={false}
              title="Adaptive Elearning"
              description=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={EWALLET}
              isBlog={false}
              title="Auction Portal"
              description=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={EWALLET}
              isBlog={false}
              title="Online Review System"
              description=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={EWALLET}
              isBlog={false}
              title="Network Intrusion Detection"
              description=""
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
