import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tilt from "react-parallax-tilt";
import { AiFillGithub, AiOutlineTwitter } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

import { logPageView } from "../../utils/g-analytics";
import { AVATAR } from "../../assetsData/index";

function Home2() {
  useEffect(() => {
    logPageView();
  }, []);

  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              I fell in love with exploring new technologies at very early age,
              and I’ve spent over 7 years building products (working) that have
              served millions of users. 🤷‍♂️
              <br />
              <br />
              I’m fluent in a variety of technologies, constantly
              <i>
                <b className="purple"> experimenting with new tools </b>
              </i>
              and integrating them into client projects when they prove useful.
              <br />
              <br />
              My areas of interest include <b className="purple">solving </b>
              tech problems,
              <i>
                <b className="purple">learning </b> system and database design,
                and <b className="purple">building </b> modern web solutions.
                &nbsp;
              </i>
              <br />
              <br />I value{" "}
              <i>
                <b className="purple">teamwork and collaboration </b>{" "}
              </i>{" "}
              , working with cross-functional teams to
              <i>
                {" "}
                <b className="purple">align solutions </b>{" "}
              </i>{" "}
              with{" "}
              <i>
                <b className="purple">business goals.</b>{" "}
              </i>
              <br />
              <br />
              Whenever possible, I also apply my passion for developing products
              with <b className="purple">
                latest technologies & architectures
              </b>{" "}
              to deliver value for users and stakeholders.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={AVATAR} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/nirajshar"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/nirajshar"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/niraj7654/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
